// Recurso (fallback) para iterar Arrays
if (!Array.prototype.forEach) {
    Array.prototype.forEach = function (fn, scope) {
        for (var i = 0, len = this.length; i < len; ++i) {
            fn.call(scope, this[i], i, this);
        }
    };
}

// Recurso de injetar variáveis em função
if (!Function.prototype.inject) {
    Function.prototype.inject = function (localVars) {
        var code = String(this).trim().replace(/^function\s*(anonymous)?(\((\n)?\))?\s*\{*\s*/, '').replace(/\s*\}?$/, '');
        var variables = '';
        for (var name in localVars) {
            var content = localVars[name];
            switch (typeof (content)) {
                case 'string':
                    content = '"' + content.replace(/\"/gi, '\\"') + '"';
                    break;
                case 'object':
                    content = JSON.stringify(content);
                    break;
            }
            variables += 'var ' + name + ' = ' + String(content) + ';';
        }
        return Function(variables + code);
    };
}

(function ($) {

    // Plugin ajuda a verificar se a variável tem um valor válido
    $.isEmpty = function (value) {
        return value === null || typeof value === 'undefined' || (typeof value === 'string' ? !value.trim().length : false) || $.isEmptyObject(value);
    };

    // Transforma string em uma expressão regular "escapada"
    $.stringToRegex = function (str, flags, exactly) {
        var wrapper = exactly ? '\\b' : '';
        return new RegExp(wrapper + str.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&').replace(/\s/g, '\\s') + wrapper, flags ? flags : undefined);
    };

    // Auxilia na conversão para um novo objeto, ['teste', 'isso' => 'se torna isso'] => {teste: 'teste', isso: 'se torna isso'}
    $.arrayToObject = function (obj) {
        var reconstructed = {};
        for (var index in obj) {
            var item = obj[index];
            reconstructed[$.isNumeric(index) ? item : index] = item;
        }
        return reconstructed;
    };


    // Mini plugin une objeto em string
    $.objectToString = function (obj, separator) {
        var result = [];
        for (var p in obj) {
            if (obj.hasOwnProperty(p)) {
                result.push(obj[p]);
            }
        }
        return result.join(separator);
    };

    // Mini plugin filtra objeto criando um novo com as keys passadas ou um objeto de conversão de chave
    $.filterObject = function (obj, pattern) {
        if (!pattern || typeof pattern !== 'object' || !Object.keys(pattern).length) {
            return {};
        }
        var reconstruct = {};
        for (var index in pattern) {
            var key = pattern[index];
            reconstruct[key] = obj[$.isNumeric(index) ? key : index];
        }
        return reconstruct;
    };

    //  Plugin que impede que o formulário seja novamente submetido e cause duplicidad
    $.fn.preventDoubleSubmission = function () {
        $(this).on('submit', function (e) {
            var $form = $(this);

            if ($form.data('submitted') === true) {
                // Já foi submetido, não submete novamente!
                e.preventDefault();
            } else {
                // Marca como submetido
                $form.data('submitted', true);
            }
        });
        return this;
    };

    // Busca elementos com um determinado texto ou valor
    $.fn.search = function (regex) {
        var list = [];
        $(this).each(function () {
            var cloned = $(this).clone();
            if (cloned) {
                cloned.children().remove();
                if ((cloned.text() && cloned.text().match(regex)) || (cloned.val() && cloned.val().match(regex))) {
                    list.push(this);
                }
            }
        });
        return $(list);
    };

    // Faz uma marcação em texto de elementos (conteúdo ou valor)
    $.fn.surround = function (regex, classes) {
        return $(this).each(function () {
            if (this.tagName.toUpperCase() === 'INPUT' || this.tagName.toUpperCase() === 'TEXTAREA') {
                var start = $(this).val().toLowerCase().indexOf(text.toLowerCase());
                var end = start + text.length;
                this.setSelectionRange(start, end);
            } else {
                $(this).html($(this).html().replace(regex, function (match) {
                    return '<span class="' + classes + '">' + match + '</span>';
                }));
            }
        });
    };

    // Mini plugin indica se o elemento indicado partiu (é ele ou é filho) de outro elemento
    $.fn.cameFrom = function (element) {
        return $(this).is(element) || $(this).closest(element).length;
    };

    // Plugin serializa formulário para objeto
    $.fn.serializeObject = function () {
        var o = {};
        var a = this.serializeArray();
        $.each(a, function () {
            if (o[this.name]) {
                if (!o[this.name].push) {
                    o[this.name] = [o[this.name]];
                }
                o[this.name].push(this.value || '');
            } else {
                o[this.name] = this.value || '';
            }
        });
        return o;
    };

    // Pega a posição relativa (em comparação) a outro elemento
    $.fn.relative = function (element) {
        var myOffset = $(this).offset();
        var otherOffset = $(element).offset();
        var final = {};
        for (var item in myOffset) {
            final[item] = myOffset[item] - otherOffset[item];
        }
        return final;
    };

})(jQuery);

class DataUtilitarios {
    /**
     *
     * @param {Date} data
     * @returns boolean
     */
    static ehUmaDataValida(data) {
        return data instanceof Date && !isNaN(data);
    }

    /**
     *
     * @param {Date} data
     * @param {Date} dataInicial
     * @param {Date} dataFinal
     * @returns
     */
    static dataEstaEntre(data, dataInicial, dataFinal) {
        return data >= dataInicial && data <= dataFinal;
    }

    /**
     * @param {string} data - Data no formato dd/mm/YYYY
     * @returns
     */
    static stringParaData(data) {
        const [ dia, mes, ano] = data.split('/')
        return new Date(ano, Number(mes -1), dia);
    }
}
