$(document).ready(function () {
    $('#accordion_bg_tasks').accordion({
        collapsible: true,
        autoHeight: true,
        active: false,
        icons: {
            header: 'ui-icon-minus',
            headerSelected: 'ui-icon-triangle-1-n'
        },
        changestart: function (event, ui) {
        }
    });

    $(".flip").click(function () {
        if (!$(".panel").is(':visible')) {
            $(".flip").animate({}, "fast");
            $(".panel").delay(200).slideDown("fast");
        } else {
            $(".panel").slideUp("fast");
            $(".flip").delay(200).animate({}, "fast");
        }
    });
});