/**
 * moeda
 *
 * @abstract Classe que formata de desformata valores monetários
 * em float e formata valores de float em moeda.
 *
 * @author anselmo
 *
 * @example
 * moeda.formatar(1000)
 * >> retornar 1.000,00
 * moeda.desformatar(1.000,00)
 * >> retornar 1000
 *
 * @version 1.0
 **/

function testForEnter() {
    if (event.keyCode === 13) {
        event.cancelBubble = true;
        event.returnValue = false;
    }
}

function replaceAll(str, needle, replacement) {
    return str.split(needle).join(replacement);
}

var moeda = {
    /**
     * retiraFormatacao
     * Remove a formatação de uma string de moeda e retorna um float
     * @param {Object} num
     */
    desformatar: function (num) {
        if (typeof num === 'string' || num instanceof String) {
            num = replaceAll(num, ".", "");
            num = num.replace(",", ".");
            return parseFloat(num);
        }
    },
    /**
     * formatar
     * Deixar um valor float no formato monetário
     * @param {Object} num
     */
    formatar: function (num) {
        x = 0;
        if (num < 0) {
            num = Math.abs(num);
            x = 1;
        }
        if (isNaN(num)) {
            num = "0";
        }
        cents = Math.floor((num * 100 + 0.5) % 100);
        num = Math.floor((num * 100 + 0.5) / 100).toString();
        if (cents < 10) {
            cents = "0" + cents;
        }
        for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++)
            num = num.substring(0, num.length - (4 * i + 3)) + '.' + num.substring(num.length - (4 * i + 3));
        ret = num + ',' + cents;
        if (x === 1) {
            ret = ' -' + ret;
        }
        return ret;
    },
    /**
     * arredondar
     * @abstract Arredonda um valor quebrado dentro do numero de casas decimais
     * desejado, não sendo informando o número de casas decimais o padrão será
     * de 2 casas após a virgula.
     * @param {Object} num
     * @param {Object} decimais
     */
    arredondar: function (num, decimais) {
        if (isNaN(decimais)) {
            decimais = 2;
        }

        return Math.round(num * Math.pow(10, decimais)) / Math.pow(10, decimais);
    },
    /**
     * Usado para cálculos de formulas do laboratorio
     * pois se usar o formatar irá arredondar sempre, e irá alterar o resultado
     * final de um calculo
     * @param {type} num
     * @returns {undefined}
     */
    toNumber: function (num) {
        //converte para string para garantir que o replace abaixo funcione
        num = num.toString();
        num = num.replace('.', '');
        num = num.replace(',', '.');

        num = parseFloat(num);

        if (isNaN(num)) {
            num = 0;
        }

        return num;
    },
    /**
     * Usado no calculo de formulas do laboratório
     * para converter um valor com ponto em virgula
     * @param {type} num
     * @returns {unresolved}
     */
    toString: function (num) {
        num = num.toString();

        num = num.replace('.', ',');

        return num;
    },

    /**
     * Método responsável por formatar um valor em moeda do Brasil.
     * minimumFractionDigits = Número de casas depois da virgula.
     *
     * @param {string} valor
     * @param {int} minimumFractionDigits
     */
    toMoeda: function (valor, minimumFractionDigits = 4) {
        return valor.toLocaleString("pt-BR", {
            minimumFractionDigits: minimumFractionDigits
        });
    }
};