'use strict';

/**
 *  jQuery Inovadora Tree
 *  Widget para autocomplete em árvore
 *  
 *  Obs.: manter a versão MINIFICADA em funcionamento (pode-se usar o plugin do netbeans para gerar com o nome correto).
 *
 *  ECMAScript 5
 *
 * @category Widget
 * @package Core
 * @author   Elisabeth Koroll <elisabeth@inovadora.com.br>
 * @author   Davi Guindani <davi@inovadora.com.br>
 * @author   Jackson Veroneze <jackson@inovadora.com.br>
 * @author   Mario Mendonça <mario@inovadora.com.br>
 * @author   Mateus Calza <mateus@inovadora.com.br>
 * @license  http://inovadora.com.br/licenca  Inovadora
 * @link     #
 * @since    01.05.000
 */

(function ($) {
    // Plugin para mostrar em árvore
    $.fn.tree = function (options) {
        // Define a configuração
        var config = $.extend({
            cacheLength: 0,
            selectFirst: true,
            recordText: false,
            busca: false,
            onItemSelect: function () {
            },
            hidden: $(this)
        }, options);

        // Itera todos os inputs para que seja possível aplicar árvore a mais de um se for o caso
        $(this).each(function () {
            var input = $(this).attr('autocomplete', 'off');

            // Cria a lista assim que o widget é definido
            var list = $('<div></div>', {
                addClass: 'tree-list',
                width: input.width() + 2
            }).data('input', input).appendTo('body');

            // Cria um botão para indicação de estado do autocomplete e para abrir/fechar autocomplete
            var button = $('<button></button>', {
                addClass: 'tree-button',
                type: 'button'
            }).data('input', input).appendTo(input.parent('dd'));

            if (input.attr('disabled') === 'disabled') {
                button.attr('disabled', 'disabled');
            }

            input.data('list', list);
            input.data('button', button);

            // Requisição privada
            var request = null;

            // Timeouts de digitação
            var typing = null;

            // ACTIONS
            var actions = {
                render: function (title, items, place, currentLevel) {
                    // Se for dentro de um li, coloca como em árvore
                    if (place.prop('tagName').toUpperCase() === 'LI') {
                        if (place.find('.tree-sub').length) {
                            place = place.find('.tree-sub');
                        } else {
                            place = $('<div></div>', {
                                addClass: 'tree-sub'
                            }).appendTo(place);
                        }
                    }

                    place.html('<span class="title">' + title + '</span>');

                    // Renderiza os itens na tela
                    var ul = $('<ul></ul>');
                    for (var index in items) {
                        var item = items[index];

                        var content = '';
                        if (currentLevel.format) {
                            content = Function('return ' + currentLevel.format + ';').inject(item).apply(input);
                        } else {
                            content = $.objectToString(item, ' - ');
                        }

                        var li = $('<li></li>')
                                .data('level', currentLevel)
                                .data('item', item)
                                .data('value', currentLevel.value ? item[currentLevel.value] : null)
                                .data('text', currentLevel.text ? Function('return ' + currentLevel.text + ';').inject(item).apply(input) : null)
                                .html(content);
                        ul.append(li);
                    }

                    if (items.length) {
                        place.append(ul);
                    } else {
                        place.append($('<small></small>').text('Sem registros compatíveis.'));
                    }
                    list.trigger('show');
                    actions.position();
                },
                // Seleciona próximo item da lista
                next: function (keyDownEvent) {
                    var next = list.find('ul li.selected').next('li');
                    if (!next.length) {
                        next = list.find('ul li').eq(0);
                    }
                    next.trigger('select', {keyDownEvent: keyDownEvent});
                },
                // Seleciona item anterior da lista
                prev: function (keyDownEvent) {
                    var next = list.find('ul li.selected').prev('li');
                    if (!next.length) {
                        next = list.find('ul li').eq(0);
                    }
                    next.trigger('select', {keyDownEvent: keyDownEvent});
                },
                // Configura posicionamento
                position: function () {
                    list.css({
                        top: input.offset().top + input.height() + 6,
                        left: input.offset().left
                    });
                },
                request: function (url, data) {
                    if (request && request.readyState < 4 && request.abort) {
                        request.abort();
                    }
                    button.addClass('loading');
                    request = $.getJSON(url, data);
                    request.complete(function () {
                        button.removeClass('loading');
                    });
                    return request;
                },
                // Abre um nível
                openLevel: function (level, data, parentItem) {
                    if (!data) {
                        data = {};
                    }
                    // Se não estiver vindo nível então é o primeiro
                    if (!level) {
                        level = config.niveis;
                        if (level && level.with) {
                            for (var index in level.with) {
                                data[index] = $(level.with[index]).val();
                            }
                        }
                    }

                    // Carrega nível
                    actions.request(level.action, data)
                            .success(function (items) {
                                // Mostra
                                list.trigger('show', {ajaxSuccessEvent: this});

                                // Nível inicial
                                if (level === config.niveis) {
                                    actions.render(level.title, items, list, level);
                                }
                                // Subníveis
                                else {
                                    actions.render(level.title, items, parentItem, level);
                                }
                            })
                            .error(actions.report);
                },
                // Reportar quando algo inesperado acontece
                report: function () {
                    if (arguments[1] !== 'abort') {
                        console.error('Autocomplete error', arguments);
                    }
                }
            };

            // EVENTS

            // Quando focar no input
            input.on('click', function (evt, force) {
                if ((!evt.isTrigger || force)) {
                    list.trigger('open', {focusEvent: evt});
                }
            });

            // Quando um item for selecionado
            input.on('set', function (evt, data) {
                list.trigger('hide');
                input.val(data.text);
                $(config.hidden).val(data.value);
            });

            // Quando há um resultado em lista ou final
            input.on('result', function (evt, data) {
                Function(config.callback).inject({
                    result: data
                }).call(input, data);
            });

            // Quando passar o mouse por algum item
            list.on('mouseenter', 'ul li', function (evt) {
                $(this).trigger('select', {mouseEnterEvent: evt});
            });

            // Quando passar o mouse por algum item
            list.on('select', 'ul li', function () {
                if (!$(this).find('.tree-sub').length) {
                    $('.tree-list ul li.selected').removeClass('selected');
                    $(this).addClass('selected');
                }
            });

            // Quando clicar algum item
            list.on('click', 'ul li', function (evt) {
                if (!$(this).find('li').length) {
                    list.trigger('open', {
                        clickEvent: evt,
                        level: $(this).data('level'),
                        item: $(this).data('item'),
                        element: $(this)
                    });
                }
            });
            
            // Quando abrir algum item
            list.on('open', function (evt, info) {
                var level = info && info.level ? info.level : null;
                var keep = level ? $.arrayToObject(level.keep) : null;
                var child = level ? level.child : null;
                var item = info && info.item ? info.item : null;
                var parentItem = info && info.element ? info.element : null;
                var value = parentItem ? parentItem.data('value') : null;
                var text = parentItem ? (parentItem.data('text') !== null ? parentItem.data('text') : parentItem.text()) : null;
                var data = $.filterObject(item, keep);

                if (child && child.with) {
                    for (var index in child.with) {
                        data[index] = $(child.with[index]).val();
                    }
                }

                var final = false;
                if (level && level.selectable) {
                    if (typeof level.selectable === 'string') {
                        final = Function('return ' + level.selectable + ';').inject(item).apply(parentItem);
                    } else {
                        final = true;
                    }
                }

                $('.tree-list ul li.selected').removeClass('selected');

                if (final) {
                    input.trigger('set', {
                        text: text,
                        value: value,
                        item: item
                    });
                } else {
                    actions.openLevel(child, data, parentItem);
                    actions.position();
                }
            });

            // Quando digitar algo
            input.on('input', function (evt) {
                if (input.val().length) {
                    if (typing !== null) {
                        window.clearTimeout(typing);
                        typing = null;
                    }
                    typing = window.setTimeout(function () {
                        input.trigger('search', {
                            query: input.val()
                        });
                    }, config.busca.delay);
                }
            });

            // Quando buscar algo
            input.on('search', function (evt, info) {
                var data = {
                    query: info.query,
                    searchType: config.busca.searchType
                };

                if (config.busca && config.busca.with) {
                    for (var index in config.busca.with) {
                        data[index] = $(config.busca.with[index]).val();
                    }
                }

                actions.request(config.busca.action, data).success(function (result) {
                    list.trigger('result', {
                        result: result,
                        query: info.query
                    });
                });
            });

            // Quando chegar algum resultado para o autocomplete
            list.on('result', function (evt, info) {
                actions.render('Resultados para "' + info.query + '"', info.result, list, config.busca);
            });

            // Quando fechar a lista
            list.on('hide', function () {
                if (request && request.readyState < 4 && request.abort) {
                    request.abort();
                }
                list.hide();
            });

            // Quando fechar a lista
            list.on('show', function () {
                list.show();
            });

            // Quando clica no botão
            button.on('click', function (evt) {
                evt.preventDefault();
                if (list.is(':visible')) {
                    $(this).trigger('hide', {clickEvent: evt});
                } else {
                    list.trigger('open', {clickEvent: evt});
                }
            });

            // Se clicar fora fecha
            $(document).on('mousedown', function (evt) {
                if (list.is(':visible') && !$(evt.target).cameFrom(list) && !$(evt.target).cameFrom(input)) {
                    list.trigger('hide', {clickEvent: evt});
                }
            });

            // Sair do widget
            $(document).on('focus', 'input, textarea, a, button, select, [tabindex]', function (evt) {
                if (list.is(':visible') && !$(evt.target).cameFrom(list) && !$(evt.target).cameFrom(input)) {
                    list.trigger('hide', {focusEvent: evt});
                }
            });

            // Controle por teclado em escopo global
            $(document).on('keydown', function (evt) {
                var keyCode = evt.keyCode || evt.which;
                if (list.is(':visible')) {
                    // Seta para cima
                    if (keyCode === 38) {
                        evt.preventDefault();
                        actions.prev(evt);
                    }
                    // Seta para baixo
                    else if (keyCode === 40) {
                        evt.preventDefault();
                        actions.next(evt);
                    }

                    // Enter
                    else if (keyCode === 13) {
                        evt.preventDefault();
                        var li = list.find('ul li.selected');
                        list.trigger('open', {
                            keyDownEvent: evt,
                            level: li.data('level'),
                            item: li.data('item'),
                            element: li
                        });
                    }
                }
            });

        });
    };
})(jQuery);
