var esusCommon = {
    verificaObrigatoriedadeEquipe: function (idUps, idProfissional, idCbo) {
        var retorno = null;

        $.ajax({
            url: '/cadastro/upsequipe/getequipeporcboupsprofissional',
            data: {
                id_ups: idUps,
                id_profissional: idProfissional,
                id_cbo: idCbo
            },
            type: 'get',
            dataType: 'json',
            async: false,
            success: function (data) {
                retorno = data;
            }
        });

        return retorno;
    },
    verificaObrigatoriedadeEquipeExcetoTiposEquipe: function (idUps, idProfissional, idCbo, tiposEquipe) {
        var retorno = null;

        $.ajax({
            url: `/cadastro/upsequipe/getequipeporcboupsprofissional`,
            data: {
                id_ups: idUps,
                id_profissional: idProfissional,
                id_cbo: idCbo,
                tipos_equipe_excluir: tiposEquipe
            },
            type: 'get',
            dataType: 'json',
            async: false,
            success: function (data) {
                retorno = data;
            }
        });

        return retorno;
    },
    obrigaDesobrigaFieldEquipe: function (idUps, idProfissional, idCbo, nameFieldEquipe) {
        setTimeout(function () {
            var dataEquipe = esusCommon.verificaObrigatoriedadeEquipe(idUps, idProfissional, idCbo);

            if (dataEquipe != undefined && dataEquipe.id_equipe) {
                $('#' + nameFieldEquipe).val(dataEquipe.id_equipe);
                $('#text_' + nameFieldEquipe).val(dataEquipe.equipe);
                $('#text_' + nameFieldEquipe).parent().siblings('label').addClass('required');
                adicionaRemoveRequerido('#text_' + nameFieldEquipe + '-label label', true);
            } else {
                $('#' + nameFieldEquipe).val('');
                $('#text_' + nameFieldEquipe).val('');
                $('#text_' + nameFieldEquipe).parent().siblings('label').removeClass('required');
                adicionaRemoveRequerido('#text_' + nameFieldEquipe + '-label label', false);
            }
        }, 500);
    }
};