(function ($) {
    /**
     *  Plugin que impede que o formulário seja novamente submetido e cause duplicidade
     *
     */
    $.fn.preventDoubleSubmission = function () {
        $(this).on('submit', function (e) {
            var $form = $(this);
            if ($form.data('submitted') === true) {
                // Já foi submetido, não submete novamente!
                e.preventDefault();
            } else {
                // Marca como submetido
                $form.data('submitted', true);
            }
        });

        return this;
    };
    /**
     * Plugin para limpar todos os caractéres exceto o que for indicado nos parâmetros
     *
     * @param options Configurações do que NÃO SERÁ LIMPO (será mantido)
     * @return object   jQuery element
     */
    $.fn.cleanExcept = function (options) {
        var config = $.extend({// VAI MANTER
            letters: false, // letras
            lettersWithAccents: false, // letras com acentos
            numbers: false, // números
            spaces: false, // espaços
            dots: false, // pontos
            commas: false, // vírgulas
            multipleSpaces: false, // múltiplos espaços, false os converte para espaço único
            scraps: false, // sobras (espaços, tabulações e quebras à esquerda e à direita), false usa trim
            others: null, // outros caractéres (símbolos de expressão regular para representar)
            lowercase: false, // letras minúsculas (false torna tudo maiusculo)
            consonantRepetitions: false, // repetiçoes (triplas ou mais) de consoantes são removida a não ser que sejam mantidas com true
            vowelRepetitionsAfterConsonant: false, // repetiçoes (triplas ou mais) de vogais após uma consoante são removidas a não ser que sejam mantidas com true
            vowelRepetitions: false, // repetiçoes (triplas ou mais) de vogais são removidas a não ser que sejam mantidas com true
        }, options);
        var expression = new RegExp('[^'
                + (config.letters ? 'A-Za-z' : '')
                + (config.lettersWithAccents ? 'ÁÍÓÚÉÄÏÖÜËÀÌÒÙÈÃÕÂÎÔÛÊáíóúéäïöüëàìòùèãõâîôûêÇç' : '')
                + (config.numbers ? '0-9' : '')
                + (config.spaces ? '\\s' : '')
                + (config.dots ? '\\.' : '')
                + (config.commas ? '\\,' : '')
                + (config.others ? config.others : '')
                + ']', 'gi');
        $(this).each(function () {
            $(this)
                    .on('change', function (e) {
                        var newVal = $(this).val().replace(expression, '');
                        // Conversões após modificar o valor do campo
                        if (!config.multipleSpaces) {
                            newVal = newVal.replace(/\s\s+/g, ' ');
                        }
                        if (!config.scraps) {
                            newVal = newVal.trim();
                        }
                        if (!config.lowercase) {
                            newVal = newVal.toUpperCase();
                        }

                        var words = newVal.split(' ');
                        var newWords = [];
                        for (var index in words) {
                            var word = words[index];
                            if (!config.consonantRepetitions) {
                                word = word.replace(/((?![aeiouAEIOUÁÍÓÚÉÄÏÖÜËÀÌÒÙÈÃÕÂÎÔÛÊáíóúéäïöüëàìòùèãõâîôûê])[a-zA-Z])\1{2,}.*?/gi, '$1$1');
                            }
                            if (!config.vowelRepetitionsAfterConsonant) {
                                word = word.replace(/(?![aeiouAEIOUÁÍÓÚÉÄÏÖÜËÀÌÒÙÈÃÕÂÎÔÛÊáíóúéäïöüëàìòùèãõâîôûê])[a-zA-Z](([aeiouAEIOUÁÍÓÚÉÄÏÖÜËÀÌÒÙÈÃÕÂÎÔÛÊáíóúéäïöüëàìòùèãõâîôûê]))\1{2,}.*?/gi, function (mid) {
                                    return mid.substr(0, 2);

                                });
                            }
                            if (!config.vowelRepetitions) {
                                word = word.replace(/([aeiouAEIOUÁÍÓÚÉÄÏÖÜËÀÌÒÙÈÃÕÂÎÔÛÊáíóúéäïöüëàìòùèãõâîôûê])\1{2,}.*?/gi, '$1$1');
                            }

                            newWords.push(word);
                        }

                        $(this).val(newWords.join(' '));
                    })
                    .on('focus', function () {
                        if (!config.lowercase) {
                            $(this).css('text-transform', 'uppercase');
                        }
                    })
                    .on('keypress', function (e) {
                        var specialKeys = [13, 8, 27, 46, 37, 38, 39, 40, 35, 36];
                        if (e.which !== 0 && $.inArray(parseInt(e.keyCode), specialKeys) === -1 && !e.ctrlKey) {
                            // Caractéres que não podem ser inseridos são desabilitados
                            var code = e.charCode || e.which;
                            var char = String.fromCharCode(code);
                            if (char.length && char.match(expression)) {
                                e.preventDefault();
                            }
                        }
                    });
        });
        return $(this);
    };

    // Widget para infinite scroll
    $.fn.scrollLoader = function (options) {
        // Elemento rolável
        var element = $(this);
        // Requisições
        var request = null;

        // Configurações
        var scope = $.extend({
            loadingHtml: '<img src="/images/spinner.gif" alt="Carregando" /> Carregando...',
            nextSelector: '.next',
            autoStart: true
        }, options);

        // Elemento com o link para carregar mais
        var next = element.find(scope.nextSelector);
        // Elemento com o link para carregar mais
        var innerLoader = null;
        // Filho
        var children = element.children();

        // Funções
        scope.adjust = function () {
            // Se tem itens o suficiente para scroll, oculta o link
            if (children.length && (element.prop('scrollHeight') - next.height()) > element.innerHeight()) {
                next.hide();
            } else {
                next.show();
            }
        };
        scope.analyzeScroll = function () {
            if (element.scrollTop() + element.innerHeight() >= element.prop('scrollHeight')) {
                scope.loadNext();
            }
        };
        scope.contentHeight = function () {
            var height = 0;
            children.each(function () {
                height += $(this).height();
            });
            return height;
        };
        scope.loadNext = function (evt) {
            if (evt && evt.preventDefault && evt.stopPropagation) {
                evt.preventDefault();
                evt.stopPropagation();
            }
            next = element.find(scope.nextSelector);
            if (!next.length) {
                return;
            }
            if (request && request.readyState < 4) {
                return;
            }
            scope.loader.start();
            request = $.get(next.attr('href'), scope.putNext).error(scope.loader.stop).complete(function () {
                request = null;
            });
        };
        scope.putNext = function (result) {
            next.replaceWith(result);
            next = element.find(scope.nextSelector);
            children = element.children();
            scope.loader.stop();
            scope.adjust();
        };
        scope.loader = {
            start: function (clean) {
                innerLoader = $('<div></div>').addClass('scroll-inner-loading').append(scope.loadingHtml);
                if (clean) {
                    element.html(innerLoader);
                    element.get(0).scrollTop = 0;
                } else {
                    next.after(innerLoader);
                    element.get(0).scrollTop = next.position().top + next.height();
                }
            },
            stop: function () {
                $('.scroll-inner-loading').remove();
            }
        };
        scope.startLoad = function () {
            element.find(scope.nextSelector).last().trigger('click').hide();
        };

        // Eventos
        element.on('scroll', scope.analyzeScroll);
        element.on('click', scope.nextSelector, scope.loadNext);
        scope.adjust();
        element.data('scrollLoader', scope);

        if (scope.autoStart) {
            scope.startLoad();
        }

        return element;
    };


    // Widget para data fragmentada
    $.fn.fragmentedDate = function (options) {
        // Configurações
        var config = $.extend({}, options);

        // Elementos
        var tipo = $(config.tipo);
        var hora = $(config.time);
        var valor = $(this).attr('data-widget', 'fragmentedDate');
        var label = valor.closest('dl').find('label');

        // AÇÕES PERSONALIZADAS

        // Na inicialização do widget
        valor.on('fragmentedDate.init', function () {
            // Se não usar hora, remove o item Data/Hora
            if (!hora.length) {
                tipo.find('option[value="2"]').remove();
            }
        });

        // Sempre que atualizar o tipo
        tipo.on('fragmentedDate.update', function (event, data) {
            var labelText = tipo.find('option[value="' + tipo.val() + '"]').text().replace(/\s?\/?\s?Hora/gi, '');
            label.text(labelText);

            if (tipo.val() == 2) {
                hora.closest('dl').show();
            } else {
                hora.val('').closest('dl').hide();
            }

            if (data && data.trusty) {
                valor.val('');
                hora.val('');
            }

            valor.trigger('fragmentedDate.applyMask');
        });

        // Sempre que aplicar a máscara
        valor.on('fragmentedDate.applyMask', function () {
            var mask,
                    type = parseInt(tipo.val());
            if (type === 1 || type === 2) {
                mask = '99/99/9999';
            } else if (type === 3) {
                mask = '99/9999';
            } else if (type === 4) {
                mask = '9999';
            } else if (type === 5) {
                mask = '99';
            } else if (type === 6) {
                mask = '99';
            } else if (type === 7) {
                mask = '99';
            }

            if (mask) {
                valor.mask(mask);
            }
            if (tipo.val() == 2) {
                hora.mask('99:99');
            }
        });


        // EVENTOS PADRÃO

        // Quando mudar o tipo
        tipo.on('change', function (event) {
            // console.log(event , event.isTrigger);
            tipo.trigger('fragmentedDate.update', {
                trusty: !event.isTrigger
            });
        });

        // Quando atualizar a página
        $(document).ready(function () {
            tipo.trigger('fragmentedDate.update');
        });

        valor.trigger('fragmentedDate.init');
    };

    // Widget para diagnóstico
    $.fn.diagnosis = function (options) {

        // Configurações
        var config = $.extend({
            list: false
        }, options);


        // Elementos
        var seletor = $(this).attr('data-widget', 'diagnosis');
        var hidden = $(config.hidden);
        var descHidden = $(config.descHidden);
        var hiddenList = $(config.hiddenList);
        var descHiddenList = $(config.descHiddenList);
        var terminologias = $('[data-terminologia-set="' + seletor.data('terminologia-set') + '"]').not(seletor);

        // Lista de itens selecionados (quando configurado para funcionar dessa maneira)
        var list = $('<table></table>');
        var vault = [];

        // AÇÕES PERSONALIZADAS

        // Quando inicializar o seletor
        seletor.on('diagnosis.init', function () {
            seletor.trigger('diagnosis.updateVault');

            terminologias.closest('dl').hide();
            terminologias.closest('dl').nextUntil('input', 'dd, dt').hide();

            if (config.list) {
                list
                        .addClass('div_itens')
                        .hide();

                terminologias.last().closest('dl').after(list);
            }

            descHidden.closest('dl').find('dt').remove();

            list.trigger('diagnosis.render');

            terminologias.closest('dl').hide();
            terminologias.filter('[data-terminologia="' + seletor.val() + '"]').closest('dl').show();
        });

        // Quando recarregar o widget
        seletor.on('reload', function (evt, data) {
            seletor.trigger('diagnosis.updateVault', data);
            list.trigger('diagnosis.render');
        });

        // Quando selecionar
        seletor.on('diagnosis.update', function () {
            // Limpar sempre que for trocado o tipo (CID ou CIAP)
            if (config.list) {
                //vault = [];  - comentado para aceitar os dois tipos quando é lista
            } else {
                vault = {};
            }

            list.trigger('diagnosis.updateInputs');
            list.trigger('diagnosis.render');

            terminologias.closest('dl').hide();
            terminologias.filter('[data-terminologia="' + seletor.val() + '"]').closest('dl').show();

            terminologias.val('');
        });

        // Atualizar o "cofre" de acordo com os dados populados
        seletor.on('diagnosis.updateVault', function (evt, data) {
            if (config.list) {
                vault = [];
                if (data && data.extras && !$.isEmpty(data.extras)) {
                    for (var terminologia in data.extras) {
                        var text = data.extras[terminologia];
                        vault.push({
                            item: {
                                id_terminologia_item: parseInt(terminologia)
                            },
                            id: null,
                            text: text
                        });
                    }
                }

                if (!$.isEmpty(hiddenList.val())) {
                    var ids = hiddenList.val().split(',');
                    var texts = descHiddenList.val().split(',');
                    for (var index in ids) {
                        var terminologia = ids[index];
                        var text = texts[index];

                        vault.push({
                            item: {
                                id_terminologia_item: parseInt(terminologia)
                            },
                            id: null,
                            text: text
                        });
                    }
                }
            } else {
                vault = {
                    item: {
                        id_terminologia_item: parseInt(hidden.val())
                    },
                    id: null,
                    text: descHidden.val()
                };
            }
        });

        // Quando tiver um callback de resultado em seus itens
        seletor.on('diagnosis.add', function (evt, dataElements) {

            var regexExamesEProcedimentos = /^#exame_id_terminologia_item_\d+/;
            terminologias.filter('[data-terminologia="' + seletor.val() + '"]').parent('dd').siblings('.notif-controls').remove();

            if (config.list) {
                vault.push({
                    id: $(dataElements.id).val(),
                    text: $(dataElements.text).val(),
                    item: dataElements.item
                });

                $(dataElements.id).val('');
                $(dataElements.text).val('');
            } else {
                vault = {
                    id: $(dataElements.id).val(),
                    text: $(dataElements.text).val(),
                    item: dataElements.item
                };
                if ($(config.renderNotify)[0] === true) {
                    var itens = $('<div></div>');
                    
                    if (dataElements.item != null && dataElements.item.tem_acesso_inserir_lista_problemas === true){
                        if(regexExamesEProcedimentos.test($(dataElements).attr('id'))){
                            var inserir = $('<a></a>', {
                                href: '#',
                                title: 'Inserir na lista de problemas'
                            }).click(function (evt) {
                                evt.preventDefault();
                                var cidsList = {};
                                cidsList[vault.item.id_terminologia_item] = vault.text;
                                problemas.form(null, cidsList);
                            }).addClass('cid-notif-exames').html('<i class="icon-plus-sign mt5 mr5"></i>');
                            itens = itens.addClass('notif-controls-exame').append(inserir);
                        }else{
                            var inserir = $('<a></a>', {
                                href: '#',
                                title: 'Inserir na lista de problemas'
                            }).click(function (evt) {
                                evt.preventDefault();
                                var cidsList = {};
                                cidsList[vault.item.id_terminologia_item] = vault.text;
                                problemas.form(null, cidsList);
                            }).addClass('cid-notif').text('Inserir na lista de problemas');
                            itens = itens.addClass('notif-controls').append('<i class="icon-plus-sign mt5 mr5"></i>', inserir);
                        }
                    }

                    if (dataElements.item != null) {
                        if (dataElements.item.notif && dataElements.item.tem_acesso_criar_notifnotificacao === true) {
                            var idFaprontuario = $('#idfaprontuario').val();
                            var idCid10 = dataElements.item.id;
                            if(regexExamesEProcedimentos.test($(dataElements).attr('id'))){
                                itens.append(' <a target="_blank" href="/epi/notifnotificacao/criar/id_faprontuario/' + idFaprontuario + '/id_cid10/' + idCid10 + '" title="Inserir CID notificável" class="cid-notif-exames"><i class="icon-share mt5 mr5"></i></a>');
                            }else{
                                itens.append(' <i class="icon-share mt5 mr5"></i><a target="_blank" href="/epi/notifnotificacao/criar/id_faprontuario/' + idFaprontuario + '/id_cid10/' + idCid10 + '" title="inserir na lista de problemas" class="cid-notif">Inserir CID notificável</a></div>');
                            }
                        }

                        if ($('.notify_inicio_gestacao').length) {
                            $('.notify_inicio_gestacao').remove();
                        }

                        if ($('.notify_fim_gestacao').length) {
                            $('.notify_fim_gestacao').remove();
                        }

                        if (dataElements.item.notify_inicio_gestacao) {
                            itens.append('<br /><i class="icon-plus-sign mt5 mr5"></i><a href="#" title="Iniciar controle gestacional" class="cid-notif notify_inicio_gestacao">Iniciar controle gestacional</a></div>');
                        }

                        if (dataElements.item.notify_fim_gestacao) {
                            itens.append('<br /><i class="icon-plus-sign mt5 mr5"></i><a href="#" title="Finalizar controle gestacional" class="cid-notif notify_fim_gestacao">Finalizar controle gestacional</a></div>');
                        }
                    }

                    if (regexExamesEProcedimentos.test($(dataElements).attr('id'))) {
                        var exameItemValor = $(dataElements).attr('id').match(/item_(\d+)/)[1];
                        var target = $('label[for="text_exame_id_terminologia_item_' + exameItemValor + '_cid10ptbr"]');
                        target.siblings('.notif-controls, .notif-controls-exame').remove();
                        target.after(itens);
                    }else{
                        terminologias.filter('[data-terminologia="' + seletor.val() + '"]').parent('dd').after(itens);
                    }
                }
            }

            list.trigger('diagnosis.render');
        });

        // Quando deve renderizar a lista
        list.on('diagnosis.render', function () {
            list.trigger('diagnosis.updateInputs');

            if (config.list) {
                list.empty();
                vault.forEach(function (item, key) {
                    var tr = $('<tr></tr>');
                    tr.data('item', item);
                    tr.data('key', key);

                    var deleteButton = $('<a></a>', {
                        href: '#',
                        addClass: 'btn-i-excluir'
                    });
                    deleteButton.on('click', function (evt) {
                        evt.preventDefault();
                        vault.splice(tr.data('key'), 1);
                        list.trigger('diagnosis.render');
                    });

                    var idFaprontuario = $('#idfaprontuario').val();
                    var idCid10 = item.id;
                    var strItem = '';



                    if ($(config.renderNotify) === true && item.item.notif) {
                        strItem += '<i class="icon-share mt5 mr5"></i><a target="_blank" href="/epi/notifnotificacao/criar/id_faprontuario/' + idFaprontuario + '/id_cid10/' + idCid10 + '" title="inserir na lista de problemas" class="cid-notif">Inserir CID notificável</a>';
                    }

                    var tdExcluir = $('<td></td>').append(deleteButton);
                    var tdValor = $('<td></td>').append($('<small></small>').text(String(item.text).replace('&comma;', ',')), '<br>', strItem);
                    tr.append(tdExcluir, tdValor);
                    list.append(tr);
                });

                if (vault.length) {
                    list.show();
                } else {
                    list.hide();
                }
            }
        });

        // Atualiza os campos hiddens para que depois sejam usados
        list.on('diagnosis.updateInputs', function () {
            if (config.list) {
                var value = [];
                var text = [];
                vault.forEach(function (info) {
                    value.push(String(info.item.id_terminologia_item));
                    text.push(String(info.text).replace(',', "&comma;"));
                });
                hiddenList.val(value.join(','));
                descHiddenList.val(text.join(','));
            } else {
                hidden.val(vault.item && vault.item.id_terminologia_item ? vault.item.id_terminologia_item : '');
                descHidden.val(vault.text ? vault.text : '');
                terminologias.filter('[data-terminologia="' + seletor.val() + '"]').val(vault.text ? vault.text : '');
            }
        });

        // EVENTOS PADRÃO

        // Quando mudar o tipo
        seletor.on('change', function () {
            seletor.trigger('diagnosis.update');
        });

        // Quando atualizar a página
        $(document).ready(function () {
            seletor.trigger('diagnosis.init');
        });

        seletor.trigger('diagnosis.init');
    };

})(jQuery);