function applyAutocomplete(id) {
    const hiddenElement = StormBox.default.byId(id);
    const anchor = StormBox.default.byId('anchor_' + id);
    const data = $(anchor).data();
    const references = Object
            .keys(data)
            .filter(function (item) {
                return item.substr(0, 21) === 'autocompleteReference';
            })
            .map(function (item) {
                var paramName = item.substr(21).toLowerCase();
                var elemName = data[item];
                return [paramName, StormBox.default.byId(elemName)];
            })
            .reduce(function (acc, reference) {
                acc[reference[0]] = reference[1];
                return acc;
            }, {});

    const otherParams = Object
            .keys(data)
            .filter(function (item) {
                return item.substr(0, 23) === 'autocompleteOtherParams';
            })
            .map(function (item) {
                var paramName = item.substr(23).toLowerCase();
                var elemName = data[item];
                return [paramName, elemName];
            })
            .reduce(function (acc, reference) {
                acc[reference[0]] = reference[1];
                return acc;
            }, {});

    const textElement = $('[data-autocomplete-text="' + data.autocomplete + '"]').get(0);

    return new StormBox.default({
        hiddenInput: data.autocompleteList ? StormBox.default.byArrayName(id) : hiddenElement,
        textInput: data.autocompleteList ? StormBox.default.byArrayName('text_' + id) : textElement,
        hiddenInputName: id,
        textInputName: 'text_' + id,
        multiple: data.autocompleteList,
        anchorElement: data.autocompleteList ? anchor : undefined,
        distinct: data.autocompleteDistinct,
        debounceTime: data.autocompleteDebouncetime,
        queryParam: data.autocompleteQueryparam,
        autoSelectWhenOneResult: data.autocompleteSelectfirst,
        clearOnType: data.autocompleteClearontype,
        autoFind: data.autocompleteAutofind,
        customText: data.autocompleteRecordtext,
        emptyItem: data.autocompleteEmptyitem,
        minLength: data.autocompleteMinlength,
        source: new StormBox.default.AjaxSource(data.autocompleteUrl),
        references: references,
        otherParams: otherParams,
        softErrors: true,
        messages: {
            emptyItemName: 'Nenhum',
            searchPlaceholder: 'Digite para buscar...',
            singularMultipleItems: 'item',
            pluralMultipleItems: 'itens',
            noData: 'Sem itens'
        }
    });
}

window.applyAutocomplete = applyAutocomplete;