$(function () {

    /* ajuda wiki sobre a página */
    $(document).on('dblclick', '.ajuda', function () {
        $('label, input, textarea, select, button').removeClass('ajudawiki'); //remove a classe ajudawiki dos elementos

        var module = $(this).attr('data-module');
        var controller = $(this).attr('data-controller');
        var action = $(this).attr('data-action');

        var href = '/wiki/doku.php?id=' + module + ':' + controller + ':' + action;
        window.open(href);
    });

    /* ajuda wiki sobre um campo específico */
    $(document).on('click', '.ajuda', function (e) {
        e.preventDefault();

        $('.ajuda').removeClass('active'); //remove a classe active de todos os elementos que tiverem a classe ajuda
        $(this).addClass('active'); //adiciona classe active no elemento atual

        //somente para elementos enabled pois os navegadores desabilitam eventos para os elementos disabled
        if ($('label, input:enabled, textarea:enabled, select:enabled, button:enabled').hasClass('ajudawiki')) {
            $('label, input:enabled, textarea:enabled, select:enabled, button:enabled').removeClass('ajudawiki');
        } else {
            $('label, input:enabled, textarea:enabled, select:enabled, button:enabled').addClass('ajudawiki');
        }
    });

    /* necessário para inputs disabled */
    $(document).on('focusin', 'input.ajudawiki, textarea.ajudawiki, select.ajudawiki, button.ajudawiki', function () {
        $(this).blur();//remove focus
        $('label, input, textarea, select, button').removeClass('ajudawiki'); //remove a classe ajudawiki dos elementos

        var module = $('.ajuda.active').attr('data-module');
        var controller = $('.ajuda.active').attr('data-controller');
        var action = $('.ajuda.active').attr('data-action');

        $('.ajuda').removeClass('active'); //remove classe active

        var field_id = $(this).attr('name');
        if (typeof field_id === "undefined") {
            field_id = $(this).attr('id');
        }

        var href = '/wiki/doku.php?id=' + module + ':' + controller + ':' + action + '#' + field_id;
        window.open(href);
    });

    /* necessário para inputs disabled pois os navegadores desabilitam eventos para eles */
    $(document).on('click', 'label.ajudawiki', function () {
        $('label, input, textarea, select, button').removeClass('ajudawiki');//remove a classe ajudawiki dos elementos

        var input_id = $(this).attr('for');

        var module = $('.ajuda.active').attr('data-module');
        var controller = $('.ajuda.active').attr('data-controller');
        var action = $('.ajuda.active').attr('data-action');

        $('.ajuda').removeClass('active'); //remove classe active

        var field_id = $('#' + input_id).attr('name');
        if (typeof field_id === "undefined") {
            field_id = $('#' + input_id).attr('id');
        }

        var href = '/wiki/doku.php?id=' + module + ':' + controller + ':' + action + ':' + field_id;
        window.open(href);
    });
});
