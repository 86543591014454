/***
 *
 ARQUIVO DE FUNÇÕES JAVASCRIPT PARA UTILIZAÇÃO EM TODO O SISTEMA

 - ANTES DE CRIAR UMA NOVA FUNÇÃO VERIFIQUE SE NÃO EXISTE ALGO SEMELHANTE
 - AO CRIAR UMA NOVA FUNÇÃO, ADICIONE OS COMENTÁRIOS PARA ENTENDIMENTO DA SUA UTILIZAÇÃO
 - NÃO ALTERE FUNÇÃO SEM ANTES VERIFICAR AS TELAS QUE SÃO UTILIZADAS
 */

/**
 * Rotina para adicionar ou remover a classe que informa que o campo
 * é obrigatório ou não
 *
 * @param {type} id         ID do elemento
 * @param {type} requerido  informa se o campo é obrigatório ou não
 *
 * @returns {undefined}
 */
var adicionaRemoveRequerido = function (id, requerido) {
    if (requerido) {
        $(id).addClass('required');
    } else {
        $(id).removeClass('required');
    }
};

/**
 * Rotina para atualizar as zebras da grid
 *
 * @param {type} id
 * @param {type} tag
 * @returns {undefined}
 */
var atualiza_zebra = function (id, tag) {
    var auxTag = tag ? " " + tag : ' tbody tr';
    $('#' + id + auxTag).removeClass('trover');
    $('#' + id + auxTag).removeClass('gridcor1');
    $('#' + id + auxTag).removeClass('gridcor2');

    $('#' + id + auxTag + ':even').addClass('gridcor1');
    $('#' + id + auxTag + ':odd').addClass('gridcor2');
};

/**
 * Ao postar um formulário por AJAX, caso o isValid seja falso, essa função
 * irá reescrever o HTML que resultou da requisição, para mostrar os erros de validação
 *
 * Precisa ser revista para uso no layout novo
 *
 * @param string data Retorno do HTML da ação
 */
var carregaRetornoAjaxEmHTML = function (data) {
    var breadCrumbs = '';
    if ($('#breadcrumbs').length > 0) {
        breadCrumbs = '<div id="breadcrumbs">' + $('#breadcrumbs').html() + '</div>';
    }
    var content = breadCrumbs + data;
    $('#content').html(content);
};

/**
 * Função para tratar o retorno de erro do banco e mostrar na tela para
 * o usuário
 *
 * @param {type} msg
 * @param {type} fora_padrao
 * @param {type} msg_fora
 * @param {type} id_elemento
 * @returns {undefined}
 */
var trata_erro = function (msg, fora_padrao, msg_fora, id_elemento) {

    if (typeof id_elemento === 'undefined') {
        id_elemento = 'msg_erro';
    }

    if ((fora_padrao !== '') && (msg.indexOf(fora_padrao) !== -1)) {
        msg = (msg.indexOf(fora_padrao) !== -1) ? msg_fora : msg;
    } else {
        msg = (msg.indexOf('SQLSTATE') !== -1) ? msg.split('SQLSTATE')[1] : msg;
        msg = (msg.indexOf('</p>') !== -1) ? msg.split('</p>')[0] : msg;
        msg = (msg.indexOf('ERROR:') !== -1) ? msg.split('ERROR:')[1] : msg;
        msg = (msg.indexOf('ERRO 999:') !== -1) ? msg.split('ERRO 999:')[1] : msg;
        msg = (msg.indexOf('ERRO:') !== -1) ? msg.split('ERRO:')[1] : msg;
        msg = (msg.indexOf('CONTEXT:') !== -1) ? msg.split('CONTEXT:')[0] : msg;
        msg = (msg.indexOf('duplicate key') !== -1) ? "Registro Duplicado neste cadastro!!" : msg;
    }

    $('#' + id_elemento).html("Erro: " + msg + "\nVerifique  se os dados estão corretos!");
    $('#message').slideDown();
};

function trata_erro2(msg, fora_padrao, msg_fora) {
    if ((fora_padrao != '') && (msg.indexOf(fora_padrao) != -1)) {
        msg = (msg.indexOf(fora_padrao) != -1) ? msg_fora : msg;
    } else {
        msg2 = msg;
        msg = (msg.indexOf('SQLSTATE') != -1) ? msg.split('SQLSTATE')[1] : msg;
        msg = (msg.indexOf('ERROR:') != -1) ? msg.split('ERROR:')[1] : msg;
        msg = (msg.indexOf('ERRO:') != -1) ? msg.split('ERRO:')[1] : msg;
        msg = (msg.indexOf('CONTEXT:') != -1) ? msg.split('CONTEXT:')[0] : msg;
        msg = (msg.indexOf('fk_') != -1) ? "Violação de Chave Estrangeira!!" : msg;
        msg = (msg.indexOf('_fkey') != -1) ? "Violação de Chave Estrangeira!!" : msg;

        msg = (msg.indexOf('Informação do Erro:') != -1) ? msg.split('Informação do Erro:')[1] : msg;
        msg = (msg.indexOf('Mensagem:') != -1) ? msg.split('Mensagem:')[1] : msg;

        msg = (msg.indexOf('</p>') != -1) ? msg.split('</p>')[0] : msg;

        indice_fk = 0;

        if (msg2.indexOf('fk_') > 0)
            indice_fk = msg2.indexOf('fk_');

        if (msg2.indexOf('_fkey') > 0)
            indice_fk = msg2.indexOf('_fkey');

        if (indice_fk > 0) {
            chave = (msg2.split("DETAIL:")[1]) ? msg2.split("DETAIL:")[1] : '';
            chave = (chave.split(".")[0]) ? chave.split(".")[0] : '';
            if (chave != '')
                msg = msg + ' Chave: ' + chave;

        }
    }
    return msg;
}

/**
 * Remove um elemento html da tela
 *
 * @param {type} id ID do elemtno
 * @returns {undefined}
 */
var remove_objeto = function (id) {
    if ($(id).length > 0)
        $(id).remove();
};

/**
 * usada na função de máscara
 *
 * @param {type} e
 * @returns {Window.event|window.event}
 */
var getObjEvent = function (e) {
    return !e ? window.event : e;
};

/**
 *
 * @param {type} objeto
 * @returns objeto
 * Funçao para formatar número inteiro com ponto na milhar.
 *
 */
function varyNumbMask(objeto) {
    obj = objeto;
    if (obj.val().length >= 4) {
        var m = '9'.repeat(obj.val().length);
        obj.mask(m.substr(0, (obj.val().length - 3)) + '.' + m.substr((obj.val().length - 3)));
    }
    return obj;

}
;

/**
 * Função para criar máscaras em campos
 *
 * @param {type} formato
 * @param {type} objeto
 * @param {type} event
 * @returns {campo.value|String|String.value|document.getElementById.value|separador|obj.value|undefined}
 */
var mascara = function (formato, objeto, event) {
    if (getObjEvent(event).keyCode === 8) {
        return;
    }

    campo = objeto;

    // CEP
    if (formato === 'CEP') {
        separador = '-';
        conjunto1 = 5;
        if (campo.value.length === conjunto1) {
            campo.value = campo.value + separador;
        }
    }

    // DATA
    if (formato === 'DATA') {
        $(campo).cleanExcept({
            numbers: true,
            others: '/:',
            consonantRepetitions: true,
            vowelRepetitionsAfterConsonant: true,
            vowelRepetitions: true
        });
        separador = "/";
        conjunto1 = 2;
        conjunto2 = 5;
        if (campo.value.length === conjunto1) {
            campo.value = campo.value + separador;
        }
        if (campo.value.length === conjunto2) {
            campo.value = campo.value + separador;
        }
        if (campo.value.length > 10) {
            campo.value = campo.value.substring(0, 10);
        }
        var dataTemp = campo.value.replace('_', '');

        if (dataTemp.length === 10 && !validaData(dataTemp) && dataTemp != "") {
            alert("Data inválida. '" + campo.value + "'");
            $(campo).val("");
            $(campo).focus();
            return;
        }
    }

    if (formato === 'DATAS') {
        separador = '/';
        conjunto1 = 2;
        conjunto2 = 5;
        conjunto3 = 10;
        var tamanho = campo.value.length;
        if (tamanho > conjunto3)
            tamanho = tamanho - conjunto3;
        if (tamanho === conjunto1) {
            campo.value = campo.value + separador;
        }
        if (tamanho === conjunto2) {
            campo.value = campo.value + separador;
        }
        if (tamanho === conjunto3) {
            campo.value = campo.value + ';';
        }
    }
    if (formato === 'CPF') {
        separador = '.';
        conjunto1 = 3;
        conjunto2 = 7;
        conjunto3 = 11;

        var tamanho = campo.value.length;
        if (tamanho === conjunto1) {
            campo.value = campo.value + separador;
        }
        if (tamanho === conjunto2) {
            campo.value = campo.value + separador;
        }
        if (tamanho === conjunto3) {
            campo.value = campo.value + '-';
        }

    }


    // HORA
    if (formato === 'HORA') {
        separador = ":";
        conjunto1 = 2;
        regex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

        if (campo.value.length === conjunto1) {
          if (campo.value > 23) {
            alert("Hora inválida!");
            campo.value = "";
            $(campo).focus();
          } else {
            campo.value = campo.value + separador;
          }
        }

        if (campo.value.length === 5) {
          if (campo.value.substring(3, 5) >= 60 || !regex.test(campo.value)) {
            alert("Hora inválida!");
            campo.value = "";
            $(campo).focus();
          }
        }
    }

    // TELEFONE
    if (formato === 'TELEFONE') {
        separador = '-';
        conjunto1 = 4;
        if (campo.value.length === conjunto1) {
            campo.value = campo.value + separador;
        }
    }

    // DATA HORA
    if (formato === 'DATA_HORA') {
        separador = '/';
        conjunto1 = 2;
        conjunto2 = 5;
        if (campo.value.length === conjunto1) {
            campo.value = campo.value + separador;
        }
        if (campo.value.length === conjunto2) {
            campo.value = campo.value + separador;
        }

        separador_hora = ':';
        conjunto3 = 13;

        if (campo.value.length === conjunto3) {
            if (campo.value > 23) {
                alert('Data e hora inválida!');
                campo.value = '';
            } else
                campo.value = campo.value + separador_hora;
        }

        if (campo.value.length === 16)
            if (campo.value.substring(3, 16) >= 60) {
                alert('Hora inválida!');
                campo.value = '';
            }

    }

    // DATA HORA com Separador
    if (formato === 'DATA_HORAS') {
        separador = '/';
        conjunto1 = 2;
        conjunto2 = 5;
        if (campo.value.length === conjunto1) {
            campo.value = campo.value + separador;
        }
        if (campo.value.length === conjunto2) {
            campo.value = campo.value + separador;
        }

        conjunto3 = 10;

        if (campo.value.length === conjunto3) {
            campo.value = campo.value + " ";
        }        
        
        separador_hora = ':';
        conjunto4 = 13;

        if (campo.value.length === conjunto4) {
            if (campo.value > 23) {
                alert('Data e hora inválida!');
                campo.value = '';
            } else
                campo.value = campo.value + separador_hora;
        }

        if (campo.value.length === 16)
            if (campo.value.substring(3, 16) >= 60) {
                alert('Hora inválida!');
                campo.value = '';
            }

    }
    
    return campo.value;
};

/**
 * Rotina para abrir uma tela em popup modal
 *
 * @param {type} pUrl
 * @param {type} pWidth
 * @param {type} pHeight
 * @param {type} pTop
 * @param {type} pLeft
 * @returns {Boolean|Number}
 */
var openModal = function (pUrl, pWidth, pHeight, pTop, pLeft) {
    if (window.showModalDialog) {
        return window.showModalDialog(pUrl, window, "dialogWidth:" + pWidth + "px;dialogHeight:" + pHeight + "px;dialogTop:" + pTop + "px;dialogLeft:" + pLeft + "px;");
    } else {
        try {
            window.open(pUrl, "wndModal", "width=" + pWidth + ",height=" + pHeight + ",resizable=no,modal=yes");
            return true;
        } catch (e) {
            alert("Script não confiável, não é possível abrir janela modal.");
            return false;
        }
    }
};

/**
 * Rotina para abrir telas dentro da grid
 *
 * @param {type} url
 * @param {type} titulo
 * @returns {undefined}
 */
var abreTela = function (url, titulo) {
    $.ajax({
        url: url,
        cache: false,
        async: false,
        type: 'GET',
        success: function (html) {
            $.blockUI({
                theme: true,
                draggable: true,
                bindEvents: false,
                showOverlay: true,
                title: titulo + ' <a href="javascript:;" class="btn-i-nao right fechar_msg"></a>',
                themedCSS: {
                    position: 'absolute',
                    width: 'auto',
                    height: 'auto',
                    left: '30%',
                    top: '20%'
                },
                message: html
            });
            $(".blockUI input[type=text]:not([readonly]):enabled:eq(0)").focus().select();
            $('#black').show();
            $(document).on('click', '.fechar_msg', function () {
                $.unblockUI();
                $('#black').hide();
            });
        }, error: function (xhr, desc, err) {
            $('#black').show();
            var texto = trata_erro_2(xhr.responseText, '', '');
            $.blockUI({
                theme: true,
                draggable: true,
                bindEvents: false,
                title: 'Aviso <div class="fechar_msg">Fechar</div>',
                message: texto
            });
            $('.blockOverlay').click($.unblockUI);
            $('.fechar_msg').click(function () {
                $.unblockUI();
                $('#black').hide();
            });
        }
    });
};

function getArrayObjJquery(arrayIds) {
    for (var i = 0; i < arrayIds.length; i++)
        arrayIds[i] = $('#' + arrayIds[i]);
    return arrayIds;
}

var validaData = function (valor) {
    var date = valor;

    if(!date){
        return true;
    }

    var ardt = new Array;
    var ExpReg = new RegExp("(0[1-9]|[12][0-9]|3[01])/(0[1-9]|1[012])/[12][0-9]{3}");
    ardt = date.split("/");
    erro = false;
    if (date.search(ExpReg) == -1) {
        erro = true;
    } else if (((ardt[1] == 4) || (ardt[1] == 6) || (ardt[1] == 9) || (ardt[1] == 11)) && (ardt[0] > 30))
        erro = true;
    else if (ardt[1] == 2) {
        if ((ardt[0] > 28) && ((ardt[2] % 4) != 0))
            erro = true;
        if ((ardt[0] > 29) && ((ardt[2] % 4) == 0))
            erro = true;
    }
    if (erro) {
        return false;
    } else {
        return true;
    }
};

/**
 * Método responsável por verificar se a data é valida
 * True = a hora é valida
 * False = a hora não é valida
 *
 * @param string valor
 * @returns {Boolean}
 */
var validaHora = function (valor) {
    return /^(?:([01]\d|2[0-3]):([0-5]\d))$/.test(valor);
};

/** verifica se uma data informada é maior que a data atual
 **/
function diasEntreDatas(dataFinal, dataHoje)
{
    var dateDif = {
        dateDiff: function (strDate1, strDate2) {
            return (((Date.parse(strDate1)) - (Date.parse(strDate2))) / (24 * 60 * 60 * 1000)).toFixed(0);
        }
    }
    var hoje = null;

    if (typeof dataHoje == 'undefined') {
        hoje = new Date();
        var mes_ = parseInt((hoje.getMonth() + 1), 10) < 10 ? '0' + parseInt((hoje.getMonth() + 1), 10) : parseInt((hoje.getMonth() + 1), 10);
        hoje = hoje.getDate() + '/' + mes_ + '/' + hoje.getFullYear();
    } else {
        hoje = dataHoje;
    }

    dataInicial = hoje;
    var mes, arrDataInicial, novaDataInicial, diasEntreDatas;
    mes = new Array("January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December");

    arrDataFinal = dataFinal.split('/');
    arrDataInicial = dataInicial.split('/');
    novaDataInicial = mes[(arrDataInicial[1] - 1)] + ' ' + arrDataInicial[0] + ' ' + arrDataInicial[2];
    novaDataFinal = mes[(arrDataFinal[1] - 1)] + ' ' + arrDataFinal[0] + ' ' + arrDataFinal[2];
    diasEntreDatas = dateDif.dateDiff(novaDataInicial, novaDataFinal);
    return diasEntreDatas;
}

var dataMaior = function (data2, data1) {
    var tmp2 = parseInt(data2.split("/")[2].toString() + data2.split("/")[1].toString() + data2.split("/")[0].toString());
    var tmp1 = parseInt(data1.split("/")[2].toString() + data1.split("/")[1].toString() + data1.split("/")[0].toString());

    if (tmp2 > tmp1) {
        return true;
    } else {
        return false;
    }
};

var dataIgual = function (data2, data1) {
    var tmp2 = parseInt(data2.split("/")[2].toString() + data2.split("/")[1].toString() + data2.split("/")[0].toString());
    var tmp1 = parseInt(data1.split("/")[2].toString() + data1.split("/")[1].toString() + data1.split("/")[0].toString());

    if (tmp2 === tmp1) {
        return true;
    } else {
        return false;
    }
};

/**
 * Rotina para chamar uma ação de impressão após clicar no botão imprimir, usando
 * os mesmos campos de filtro de um form
 * @param string form Nome do Formulário que será submetido
 * @param string action Ação que será executada para submeter
 *
 * @returns boolean
 */
var chamaImpressaoFiltro = function (form, action) {
    $('#' + form).attr({
        action: action,
        target: '_blank'
    });

    $('#' + form).submit();

    $('#' + form).removeAttr('action');
    $('#' + form).removeAttr('target');

    return true;
};

function calculaidade(dataNascimento) {
    var arrayData = dataNascimento.split("/");

    if (arrayData.length == 3) {
        var ano_nascimento = parseInt(arrayData[2], 10);
        var mes_nascimento = parseInt(arrayData[1], 10);
        var dia_nascimento = parseInt(arrayData[0], 10);

        var hoje = new Date()
        var mes = parseInt(hoje.getMonth() + 1, 10);
        var dia = parseInt(hoje.getDate(), 10);
        var ano = parseInt(hoje.getFullYear(), 10);

        var idade = ano - ano_nascimento;

        if (mes < mes_nascimento) {
            idade = idade - 1;
        }

        if (mes == mes_nascimento) {
            if (dia < dia_nascimento) {
                idade = idade - 1;
            }
        }
        return idade;
    } else
        return '#ERRO';
}

function trata_erro_2(msg, fora_padrao, msg_fora) {
    if ((fora_padrao !== '') && (msg.indexOf(fora_padrao) !== -1)) {
        msg = (msg.indexOf(fora_padrao) > -1) ? msg_fora : msg;
    } else {
        msg = (msg.indexOf('Mensagem:') > -1) ? msg.split('Mensagem:')[1] : msg;
        msg = (msg.indexOf('SQLSTATE') > -1) ? msg.split('SQLSTATE')[1] : msg;
        msg = (msg.indexOf('</p>') > -1) ? msg.split('</p>')[0] : msg;
        msg = (msg.indexOf('ERROR:') > -1) ? msg.split('ERROR:')[1] : msg;
        msg = (msg.indexOf('Error:') > -1) ? msg.split('Error:')[1] : msg;
        msg = (msg.indexOf('ERRO:') > -1) ? msg.split('ERRO:')[1] : msg;
        msg = (msg.indexOf('CONTEXT:') > -1) ? msg.split('CONTEXT:')[0] : msg;
        msg = (msg.indexOf('</span>') > -1) ? msg.split('</span>')[0] : msg;
    }
    msg = msg.replace('&nbsp;', ' ');
    return msg.replace(/<.*?>/g, '');
}

function toInteger(valorparacast, valorparaerrocast) {
    var val = parseInt(valorparacast, 10);
    var valaux = parseInt(valorparaerrocast, 10);
    return isNaN(val) ? isNaN(valaux) ? 0 : valaux : val;
}

function toFloat(valorparacast, valorparaerrocast) {
    var val = parseFloat(valorparacast);
    var valaux = parseFloat(valorparaerrocast)
    return isNaN(val) ? isNaN(valaux) ? 0.0 : valaux : val;
}

abre_tela = function (url, titulo, width = 'auto',height = 'auto' ) {
    $.ajax({
        url: url,
        cache: false,
        async: false,
        type: 'GET',
        success: function (html) {
            $.blockUI({
                theme: true,
                draggable: true,
                bindEvents: false,
                showOverlay: false,
                title: titulo + ' <a href=\"javascript:;\" class=\"btn-i-nao right fechar_msg\"></a>',
                themedCSS: {
                    position: 'absolute',
                    width: width,
                    height: height,
                    left: '30%',
                    top: '20%'
                },
                message: html
            });
            $(".blockUI input[type=text]:not([readonly]):enabled:eq(0)").focus().select();
            $('#black').show();
            $(document).on('click', '.fechar_msg', function () {
                $.unblockUI();
                $('#black').hide();
            });
        }, error: function (xhr, desc, err) {
            $('#black').show();
            var texto = trata_erro_2(xhr.responseText, '', '');
            $.blockUI({
                theme: true,
                draggable: true,
                bindEvents: false,
                title: 'Aviso <div class="fechar_msg">Fechar</div>',
                message: texto
            });
            $('.blockOverlay').click($.unblockUI);
            $('.fechar_msg').click(function () {
                $.unblockUI();
                $('#black').hide();
            });
        }
    });
}

$(document).ready(function () {
    $('#altera_senha, #altera_senha_index').click(function () {
        abreTela2('/conta/alterasenha', 'Alterar senha do usuário logado', 500, 420);
    });

    $(document).on('click', '#submit_alterasenha', function () {
        altera_senha();
    });

    $(document).on('keydown', '#alterasenha', function (e) {
        if (e.which === 13) {
            $('#submit_alterasenha').trigger('click');
        }
    });

    $(document).on('click', '#submit_trocasenha', function () {
        troca_senha();
    });
});

/**
 * Efetua a mudança da SENHA
 */
altera_senha = function () {
    $.ajax({
        url: '/conta/alterasenha',
        type: 'POST',
        cache: false,
        contentType: "application/x-www-form-urlencoded",
        data: $('#alterasenha').serialize(),
        beforeSend: function () {
            if ($("#loader").length > 0) {
                $("#loader").remove();
            }

            if ($("#error_message").length > 0) {
                $("#error_message").remove();
            }

            $("#submit_alterasenha").attr('disabled', 'disabled');
            $("#submit_alterasenha").after('<img id="loader" src="/img/ajax-loader-flower.gif">');
        },
        complete: function (data) {
            $("#submit_alterasenha").removeAttr('disabled');
            if ($('#loader').length > 0) {
                $('#loader').remove();
            }
        },
        success: function (data) {
            if (data['MSG'] === 'OK') {
                if(data['autenticaTrocaSenhaUsuario']){
                    window.location.href = '/conta/logout';
                } else {
                    $('.fechar_msg').click();
                }
                return true;
            } else {
                $('#fieldset-alterasenha').after("<div id='error_message' style='background-color:red;color:white; margin-bottom:5px;'>" + data['observacao'] + "</div>");
                return false;
            }
        }
    });

    return false;
}

/**
 * Efetua a mudança da SENHA quando forçada
 */
troca_senha = function () {
    $.ajax({
        url: '/conta/trocasenha',
        type: 'POST',
        cache: false,
        contentType: "application/x-www-form-urlencoded",
        data: $('#trocasenha').serialize(),
        beforeSend: function () {
            if ($("#loader").length > 0) {
                $("#loader").remove();
            }

            if ($("#error_message").length > 0) {
                $("#error_message").remove();
            }

            $("#submit_trocasenha").attr('disabled', 'disabled');
            $("#submit_trocasenha").after('<img id="loader" src="/img/ajax-loader-flower.gif">');
        },
        complete: function (data) {
            $("#submit_trocasenha").removeAttr('disabled');
            if ($('#loader').length > 0) {
                $('#loader').remove();
            }
        },
        success: function (data) {
            if (data['MSG'] === 'OK') {
                window.location.href = data.redirect;
                return true;
            } else {
                $('#fieldset-trocasenha').after("<div id='error_message' style='background-color:red;color:white; margin-bottom:5px;'>" + data['observacao'] + "</div>");
                return false;
            }
        },
        error: function (xhr, desc, err) {
            var erro = trata_erro2(data.responseText, '', '');
            $('#black').show();
            $.blockUI({
                theme: true,
                title: 'Aviso <div class="fechar_msg">Fechar</div>',
                message: erro
            });
            $('.fechar_msg').click(function () {
                $.unblockUI();
                $('#black').hide();
            });
        }
    });

    return false;
}

function isArray(obj) {
    if (obj.constructor.toString().indexOf("Array") == -1)
        return false;
    return true;
}

var abreTela2 = function (url, titulo, width, heigh, callbackFunction = null)
{
    $.ajax({
        url: url,
        type: 'get',
        success: function (html) {
            $.blockUI({
                theme: true,
                draggable: true,
                bindEvents: false,
                showOverlay: true,
                title: titulo + '<a href=\"javascript:;\" class=\"btn-i-nao right fechar_msg\"></a>',
                themedCSS: {
                    position: 'absolute',
                    width: width,
                    height: heigh,
                    left: ($(window).width() - width) / 2,
                    top: heigh === 'auto' ? 40 : (($(window).height() - heigh) / 2) * 0.4
                },
                message: html
            });
            $(".blockUI input[type=text]:not([readonly]):enabled:eq(0)").focus().select();
            $('#black').show();
            $(document).on('click', '.fechar_msg', function () {
                $.unblockUI();
                $('#black').hide();
            });

            if (typeof callbackFunction === 'function')
                callbackFunction();
        }
    });
};

var abreTela3 = function (url, titulo, width, heigh = 40, left, top, callbackFunction = null)
{
    $.ajax({
        url: url,
        type: 'get',
        success: function (html) {
            $.blockUI({
                theme: true,
                draggable: true,
                bindEvents: false,
                showOverlay: true,
                title: titulo + '<a href=\"javascript:;\" class=\"btn-i-nao right fechar_msg\"></a>',
                themedCSS: {
                    position: 'absolute',
                    width: width,
                    height: heigh,
                    left: left ? left : (($(window).width() - width) / 2),
                    top: top ? top : ((($(window).height() - heigh) / 2) * 0.4)
                },
                message: html
            });
            $(".blockUI input[type=text]:not([readonly]):enabled:eq(0)").focus().select();
            $('#black').show();
            $(document).on('click', '.fechar_msg', function () {
                $.unblockUI();
                $('#black').hide();
            });

            if (typeof callbackFunction === 'function')
                callbackFunction();
        }
    });
};

var esus = {
    verificaDataNascimentoAtendimento: function (dataNascimento, dataAtendimento) {
        var diffDias = diasEntreDatas(dataNascimento, dataAtendimento);

        if (diffDias < 0) {
            alert('A data de atendimento deve ser superior a data de nascimento.');
        }
    }
};

/**
 * Classe responsável pela criação de HTML no js
 * Para habilitar a síntaxe hightlight no VSCode, instalar a extensão lit-html
 * Usar tagged template literals
 */
class RenderElements {
    static render(data, template) {
        if (!template) return
        if (typeof data === 'string') {
            return template(data)
        } else if (typeof data === 'object' && !Array.isArray(data)) {
            return template(data)
        } else if (Array.isArray(data)) {
            return data.map(item => template(item)).join('')
        }
    }

    static html(literals, ...vars) {
        let raw = literals.raw,
        result = '',
        i = 1,
        len = arguments.length,
        str,
        variable;

        while (i < len) {
            str = raw[i - 1];
            variable = vars[i -1];
            result += str + variable;
            i++;
        }
        result += raw[raw.length - 1];
        return result;
    }
}

function contadorCaracteres(el) {

    let idElemento = el.id; 
    let idElementoCount = "count_caracteres_" + idElemento;
    let elementoParaAtualizar = document.getElementById(idElementoCount); 

    if (elementoParaAtualizar) {
        let numeroCaracteres = el.value.length;
        let maxCaracteres = el.getAttribute('maxlength');
        let complemento = '';
        if (maxCaracteres) {
            complemento = " de " + maxCaracteres;
        }
        elementoParaAtualizar.innerHTML = numeroCaracteres + complemento;
    }

}